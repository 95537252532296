<template>
    <v-card  height="100%">
        <DlgMessage ref="dlgMsg" @dlgClose="dlgClose"></DlgMessage>
        <DlgReimbursementRequest ref="dlgCommon" :visible="visible" @close="dlgClose"></DlgReimbursementRequest>
        <div class="pa-3">
          <v-toolbar>
            <v-toolbar-title>Reimbursement List</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-breadcrumbs :items="items"></v-breadcrumbs>
          </v-toolbar>
        </div>
        <div class="pa-3">
        <v-card>
        <v-card-title>
        <v-spacer></v-spacer>
        <v-btn icon dark class="primary mr-2" @click="refreshDataTable"><v-icon>mdi-refresh</v-icon></v-btn>         
        <v-btn icon dark class="mb-2 success" @click="addRecord">
            <v-icon>mdi-plus</v-icon>
        </v-btn>
        </v-card-title>
        <v-card-text>

        <v-text-field
            v-model="tblSearch"
            append-icon="mdi-magnify"
            label="Search data below"
            hide-details
            outlined
        ></v-text-field>
        </v-card-text>

        <v-data-table
        v-model="selected"  
        ref="vuetable"
        item-key="req_ref_no"
        :headers="tblHeaders"
        :items="tblBody"
        :search="tblSearch"
        :loading = "loadingFlag"
        loading-text="Loading... Please wait"    
        @click:row="openDetail"
        ></v-data-table>
        </v-card>
        </div>

    </v-card>

</template>
<script>

import DlgMessage from '@/components/DlgMessage'
import MpRdEvoucherService from "@/services/MpRdEvoucherService"
import DlgReimbursementRequest from "@/components/DlgReimbursementRequest";

export default {
    components:{DlgMessage,DlgReimbursementRequest},
    data() {
        return{
            visible:false,
            items: [
              {text:'e-Voucher',disabled: false,href:'#',},
              {text:'Reimbursement List',disabled: false,href:'#',},
              ],
            selected: [],
            tblHeaders:[
                { text:"Ref#",value: "req_ref_no"  },
                { text:"Outlet Name", value:"outlet_name" },
                { text:"Total Item", value:"total_item" },
                { text:"Total Qty", value:"total_qty" },
                { text:"Total Amount", value:"total_amount" },
                { text:"Req. Date", value:"created_date" },
                { text:"Req. By", value:"created_by" },
            ],
            tblBody:[],
            tblSearch:'',
            loadingFlag : false,
            form:{user_login_id:""},

        }
    },
    async created() {
      this.refreshDataTable();
    },
    methods:{
        async refreshDataTable(){
            this.loadingFlag = true
            try{
                MpRdEvoucherService.list_reimbursement(this.form).then((res) => {
                    //console.log(res);
                    this.tblBody = res.data.data;
                    this.loadingFlag = false;
                    this.errorFlag = false;
                }).catch((e)=>{
                    //console.log(e.response);
                    this.$refs.dlgMsg.showDlgBox(true,'error','Merchant',e.response.data.error_message);
                    this.loadingFlag = false
                    this.errorFlag = true;
                })
                ;
            } catch(e) {
                this.loadingFlag = false
                //this.$refs.dlgMsg.showDlgBox(true,'error','Merchant','Unknown Error');
                //console.log(e)
                
            }           

        },
        openDetail(value){
            let routeData = this.$router.resolve({ 
                name: 'evoucher-reimbursement-details', 
                params: { 
                    req_ref_no: value.req_ref_no,
                } 
            })
            window.open(routeData.href,'_blank')
            // this.$router.push({ name: 'point_transaction_details', params: { process_reference_no: value.process_reference_no } })
        },

        addRecord(){
            this.visible = true;
        },
        dlgClose(){
            this.visible = false;
        },
    }

}
</script>