<template>
    <v-dialog max-width="500px"  v-model="show">
    <DlgMessage ref="dlgMsg" @dlgClose="dlgClose"></DlgMessage>
        <v-card>
          <v-toolbar flat dark color="primary">
            <v-toolbar-title>Request Reimbursement</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="show=false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
            <div class="pa-5">

                            <v-row no-gutters>
                    <v-col cols="12">

                <v-form ref="mainForm">
                    <v-select
                        v-model = "form.merchant_outlet_id"
                        :items="outletList"
                        item-text="outlet_name"
                        item-value="merchant_outlet_id"
                        label="Outlet Name"
                        standard
                        :rules="inputRules"
                        outlined
                        v-if="show" autofocus
                    ></v-select>
                            <v-menu v-model="dpicker.menu1" :close-on-content-click="false" max-width="290">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        outlined
                                        dense
                                        :value="form.req_date_from"
                                        label="Redeemed Date From"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-date-picker v-model="form.req_date_from" @change="dpicker.menu1 = false"></v-date-picker>
                            </v-menu>
                            <v-menu v-model="dpicker.menu2" :close-on-content-click="false" max-width="290">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        outlined
                                        dense
                                        :value="form.req_date_to"
                                        label="Redeemed Date To"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-date-picker v-model="form.req_date_to" @change="dpicker.menu2 = false"></v-date-picker>
                            </v-menu>

                
                </v-form>
                    </v-col>
                            </v-row>
            </div>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="success" dark @click="save">
                    <v-icon left dark>mdi-check</v-icon>
                    Submit
                </v-btn>
                <v-btn class="ml-2" color="error" dark @click="show=false">
                    <v-icon left dark>mdi-close</v-icon>
                    Close
                </v-btn>
            </v-card-actions>

          </v-card>
    </v-dialog>

</template>

<script>
import DlgMessage from '@/components/DlgMessage'
import MpRdEvoucherService from "@/services/MpRdEvoucherService"
import moment from 'moment'
export default {
    name: "DlgReimbursement",
    props: {
        visible:Boolean,
    },      
    components:{DlgMessage},
    computed: {
      show: {
        get () {
          return this.visible
        },
        set (value) {
           if (!value) {
                this.$emit('close')
           }
        }
      },      
    },
    data(){
        return{
            dialog: false,
            errorFlag:0,
            form:{
                user_login_id: "",
                req_date_from: "",
                req_date_to: "",
                merchant_outlet_id: 0,
            },
            outletList: [],
            error_message:'',
            error_code: '200',
            inputRules: [
             v => !!v || 'This field is required'
            ],
            dpicker:{menu1: false,menu2: false,},
        }
    },
    async created() {
      this.getParameter();
      this.form.req_date_from = moment(String(new Date())).format('YYYY-MM-DD');
      this.form.req_date_to = moment(String(new Date())).format('YYYY-MM-DD');
    },
    mounted(){
        this.form.req_date_from = moment(String(new Date())).format('YYYY-MM-DD');
        this.form.req_date_to = moment(String(new Date())).format('YYYY-MM-DD');

    },
        
    methods:{
        dlgClose(){
            if(!this.errorFlag){
                this.show = false;
            }                
        },                
        async save(){
            //console.log(this.form);
            if(this.$refs.mainForm.validate()) {
                try{
                
                    MpRdEvoucherService.req_reimbursement(this.form).then(() => {
                        //console.log(res);
                        this.$refs.dlgMsg.showDlgBox(true,'success','Request Reimbursement','Success.');
                        this.errorFlag = 0;
                    }).catch((e)=>{
                        //console.log(e.response);
                        this.$refs.dlgMsg.showDlgBox(true,'error','Request Reimbursement',e.response.data.error_message);
                        this.errorFlag = 1;
                    })
                    ;
                } catch(e) {
                    this.loadingFlag = false
                    this.$refs.dlgMsg.showDlgBox(true,'error','Request Reimbursement','Unknown Error');
                    
                } 
            }

        },
        async getParameter(){
            try{
                MpRdEvoucherService.list_outlet(this.form).then((res) => {
                    this.outletList = res.data.data;
                    //console.log(res.data);
                    
                }).catch((e)=>{
                    //console.log(e.response);
                    this.$refs.dlgMsg.showDlgBox(true,'error','View User',e.response.data.error_message);
                })
                ;
            } catch(e) {
                //console.log(e);
            }
        },        
    }
}    

</script>